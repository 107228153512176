import React, { useState, useEffect } from "react";


function ImageGrid({ items }) {
  return (
    <div className="grid grid-cols-1 gap-20 max-w-screen-xl mx-auto">
      {items.map((item, index) => (
        <div key={index} className="flex flex-col sm:flex-row gap-5 items-start">
          <div className="w-full sm:w-1/2 mb-6 sm:mb-0">
            <div className="flex flex-col justify-start items-start">
              <h2 className="font-bold text-sm mb-2 mt-0">{item.caption}</h2>
              <p className="text-xs mb-4">{item.description}</p>
            </div>
          </div>
          <div className="w-full sm:w-1/2">
            <img
              src={item.url}
              alt={item.caption}
              className="w-full h-auto object-cover"
            />
          </div>
        </div>
      ))}
    </div>
  );
}




function ProjectDetail({ project, onClose }) {
  const items = [
    {
      url: 'https://portfolio-elisa-2023.s3.eu-west-1.amazonaws.com/images/FREAKYPARKEN.png',
      caption: 'DJ set poster',
    },
    {
      url: 'https://portfolio-elisa-2023.s3.eu-west-1.amazonaws.com/images/just+a+girl.png',
      caption: 'I am just a girl!!',
    },
    {
      url: 'https://portfolio-elisa-2023.s3.eu-west-1.amazonaws.com/elisacake.gif',
      caption: 'Spinning jello cake',
    },
    {
      url: 'https://portfolio-elisa-2023.s3.eu-west-1.amazonaws.com/Sequence+01_1+(2).gif',
      caption: 'Animation for a DJ morning gig',
    },
    {
      url: 'https://portfolio-elisa-2023.s3.eu-west-1.amazonaws.com/elisa.egerekkiimaniucake.gif',
      caption: 'Birthday cake model',
    },
    {
      url: 'https://portfolio-elisa-2023.s3.eu-west-1.amazonaws.com/elisalogo.3danimation+(1).gif',
      caption: 'Elisa logo',
    },
    {
      url: 'https://portfolio-elisa-2023.s3.eu-west-1.amazonaws.com/objects/nestedBFGIF_1.gif',
      caption: 'BF logo - for a friend',
    },

   
  ];
  return (
    <div className="project-detail">
      {project.title == "Indo" && (
        <div>
          <div className="flex flex-row gap-5 ">
            <div className="w-1/3 sm:w-1/2 flex-col m-2  sm:mt-0 gap-5">
              <div className="flex flex-col mt-40 sm:m-10 gap-5">
                <img src="https://portfolio-elisa-2023.s3.eu-west-1.amazonaws.com/objects/currency_conv_1.png"></img>
                <img src="https://portfolio-elisa-2023.s3.eu-west-1.amazonaws.com/objects/currency_conv_2.png"></img>
              </div>
            </div>
            <div className="flex w-2/3 sm:ml-10 flex-col gap-5">
              <div className="flex flex-col mt-10 gap-10">
                <h1 className="font-bold text-2xl ">
                  Front end programming -indo
                </h1>
                <p>
                  During my time working for indo I gained experience in
                  programming with{" "}
                  <a
                    href="https://flutter.dev/?gclid=CjwKCAjwoqGnBhAcEiwAwK-Oke5mnr0-DkNRWJnVCq-HVscwCZ8ue1_506qBjxt6sNED8eiENhojTBoC0OcQAvD_BwE&gclsrc=aw.ds"
                    target="_blank"
                    className="font-boldhover:text-bubble-gum "
                  >
                    Flutter
                  </a>
                  , which was used to develop the front end of their
                  application. Since I was new to the programming using{" "}
                  <a
                    href="https://flutter.dev/?gclid=CjwKCAjwoqGnBhAcEiwAwK-Oke5mnr0-DkNRWJnVCq-HVscwCZ8ue1_506qBjxt6sNED8eiENhojTBoC0OcQAvD_BwE&gclsrc=aw.ds"
                    target="_blank"
                    className="font-bold hover:text-bubble-gum"
                  >
                    Flutter
                  </a>
                  , my first tasks revolved around all sorts of bug fixes in
                  order to learn to navigate through the application and learn
                  the basics. As my knowledge grew deeper I was trusted with
                  larger tasks, and the most important feature I was trusted
                  with was the currency converter, which I also got to design
                  using{" "}
                  <a
                    href="https://www.figma.com/files/recents-and-sharing/recently-viewed?fuid=1163784556324050388"
                    target="_blank"
                    className="font-bold hover:text-bubble-gum"
                  >
                    Figma
                  </a>
                  .
                </p>
                <p>
                  It uses a backend API that regularly updates each currency,
                  which is sourced from{" "}
                  <a
                    href="https://enfuce.com/"
                    target="_blank"
                    className="font-bold hover:text-bubble-gum "
                  >
                    Enfuce
                  </a>
                  . The converter has a larger selection of curencies than any
                  other icelandic bank app, and prioritizes most recently used
                  currencies to optimize the client experience.
                </p>
              </div>
            </div>
          </div>
        </div>
      )}
      {project.title == "InkUp" && (
        <div>
          <div className="flex flex-row gap-5 ">
            <div className="w-1/3 sm:w-1/2 flex-col m-2  sm:mt-0 gap-5">
              <div className="flex flex-col mt-10  sm:m-10 gap-5">
                <img src="https://portfolio-elisa-2023.s3.eu-west-1.amazonaws.com/objects/home_inkUp.png"></img>
                <img src="https://portfolio-elisa-2023.s3.eu-west-1.amazonaws.com/objects/browse_inkUp.png"></img>
              </div>
            </div>
            <div className="flex w-2/3 sm:ml-10 flex-col gap-5">
              <div className="flex flex-col mt-10 gap-10">
                <p>
                  <h1 className="font-bold text-2xl ">inkUp</h1>
                </p>
                <p>
                  inkUp is a startup idea that was born when I took an
                  entrepreneurially focused course in the University of Iceland.
                  It is a platform that connects tattoo artists to potential
                  customers in order to make the tattoo industry less
                  underground than it is. The idea was born when me and my
                  friends were going on a trip to Berlin and wanted to get
                  tattoos, but had nowhere to look since we didnt know anyone
                  that could advise us on where to look. That is when I thought
                  that having a platform that connects where clients can get an
                  overview on all artists and their portfolios in their nearby
                  area would benefit both parties. Artists would get more
                  exposure and clients would have easier access to the art they
                  wish for, since getting a tattoo is a lifelong decision.
                </p>
                <p>
                  In the course I took in Iceland I made a business model for
                  inkUp along with a business pitch that was pitched to
                  investors and important people in the tech industry. I then
                  took another similar course in Madrid when I re-iterated the
                  business model and pitch, after getting artists and clients to
                  take a number of surveys in order to get more in depth
                  knowledge on inkUp's target market. I also designed the whole
                  front end logic for the application using{" "}
                  <a
                    href="https://www.figma.com/file/GfvqcwaPWDfIIeFbpFnztH/InkUp?type=design&node-id=0%3A1&mode=design&t=J6hbyZs2IEmJRH1z-1"
                    className="font-bold text-xl hover:text-bubble-gum "
                  >
                    Figma
                  </a>{" "}
                  and using a mock database, implemented a mock version of the
                  application using Adalo, which is accesible{" "}
                  <a
                    href="https://previewer.adalo.com/11a6897d-d71b-4c3f-9c08-7f6d59bc061b"
                    className="font-bold text-xl hover:text-bubble-gum "
                  >
                    here
                  </a>
                  .
                </p>
              </div>
            </div>
          </div>
        </div>
      )}
      {project.title == "Blender museum" && (
     
        <div className="flex flex-col gap-6">
          <ImageGrid items={items} />
        
         
        </div>
      )}
      {project.title == "Pikkolo optimization" && (
        <div className="flex flex-col-reverse sm:flex-row">
          <div className="flex-1 flex-col sm:ml-10 sm:mr-20 mt-20">
            <div className="flex flex-col gap-1">
              <img src="https://portfolio-elisa-2023.s3.eu-west-1.amazonaws.com/images/plotone.png"></img>
              <p className="font-bold text-xs">
                Likelyhood of placing pikkoló station based on population and
                traffic in 500m distance from supermarket
              </p>
            </div>
            <div className="flex flex-col gap-1 mt-20">
              <img src="https://portfolio-elisa-2023.s3.eu-west-1.amazonaws.com/images/plottwo.png"></img>
              <p className="font-bold text-xs">
                This solution uses population and traffic near each
                neighbourhood and also takes in the center of gravity of the
                surface area of private properties (green dot), such as
                workplaces. Placing a pikkoló station near a big workplace is
                favourable.
              </p>
            </div>
          </div>
          <div className="flex-1 flex-col gap-5">
            <div className="flex flex-col gap-5">
              <h1 className="text-2xl ">Motivation</h1>
              <p>
                {" "}
                <a
                  href="https://www.pikkolo.is/"
                  className="text-l font-bold hover:text-bubble-gum"
                >
                  Pikkoló{" "}
                </a>
                strives for a more sustainable future in grocery shopping by
                letting users pick up grocery orders in stations located near
                them, thus minimizing air pollution and encouraging users to be
                less dependent on daily car travels. Me and another student were
                approached by a professor who was at the time teaching us
                machine learning and operational programming and given the
                opportunity to implement a visual solution for pikkoló,
                calculating where to situate their pick up stations.
              </p>
              <h2 className="text-2xl ">Method</h2>
              <p>
                <a
                  href="https://en.wikipedia.org/wiki/Linear_regression"
                  className="text-l font-bold hover:text-bubble-gum"
                >
                  Linear regression{" "}
                </a>{" "}
                was performed on data we found appropriate for the project,
                sourced from the geographic information system of Reykjavik and
                the GPS system TomTom. Data analization and Linear Regression
                was performed using R and mapped onto a map of Reykjavik,
                creating a simple and understandeable solution that Pikkoló uses
                for potential business opportunities.
              </p>
              <h2 className="text-2xl">Outcome</h2>
              <p>
                Different maps were created, each mapping a different regression
                based on premises. The intention of doing so was that Pikkoló
                could have a more in depth backlog of maps that looked at the
                problem from different points of views. The maps show different
                neighbourhoods of the city where the more light the blue colour,
                the higher the liklelyhood of opening a pikkoló station. The red
                dots are locations of supermarkets in Reykjavík.
              </p>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default ProjectDetail;
