import React from "react";

import {
  Navbar,
  NavbarBrand,
  NavbarContent,
  NavbarItem,
  Link,
  Button,
} from "@nextui-org/react";

function Header({ handleNavigationClick }) {
  return (
    <div className="sticky top-0 bg-white z-50">
      <div className="flex flex-row justify-between border-b">
        <div className=" ml-10 sm:ml-20">
          <img
            src="https://portfolio-elisa-2023.s3.eu-west-1.amazonaws.com/elisalogo.3danimation+(1).gif"
            className="sm:w-48  w-28 " // Set your desired width and height using Tailwind CSS classes
          />
        </div>

        <div className="flex flex-row mr-10 sm:mr-20">
          <Link href="mailto:davidsson.elisa@gmail.com"   target="_blank">
            <img
              src="https://portfolio-elisa-2023.s3.eu-west-1.amazonaws.com/images/elisa.mailsymb.png"
              alt="Gmail Icon"
              className=" w-16 sm:w-28 " // Set your desired width and height using Tailwind CSS classes
            />
          </Link>

          <Link href="https://www.linkedin.com/in/elisa-bj%C3%B6rg-tryggvad%C3%B3ttir-b01404276/"   target="_blank">
            <img
              src="https://portfolio-elisa-2023.s3.eu-west-1.amazonaws.com/images/elisa.linkedinsymb.png"
              alt="LinkedIn Icon"
              className="w-16 sm:w-28 " // Set your desired width and height using Tailwind CSS classes
            />
          </Link>
        </div>
      </div>
    </div>
  );
}

export default Header;
