import React, { useState } from 'react';
import Header from './components/Header';
import About from './components/About';
import Contact from './components/Contact';
import Projects from './components/Projects';
import ProjectsDJOK from './components/ProjectsDJOK';
import Resume from './components/Resume';
import Footer from './components/Footer';
import SecondNav from './components/SecondNav';

// 1. import `NextUIProvider` component
import { NextUIProvider } from "@nextui-org/react";

function App() {
  const [content, setContent] = useState('projects');
  const [selectedProject, setSelectedProject] = useState(null); // Add this state
  const handleNavigationClick = (page) => {
    if (page === 'projects' || page === 'djok') {
      setSelectedProject(null);
    }
    setContent(page);
  };
  

  return (
    
      <div>
        <Header />
        <SecondNav handleNavigationClick={handleNavigationClick} content={content} />
        <div className='m-10 sm:m-20' style={{ minHeight: '75vh' }}>
          <main>
            {content === 'projects' && (
              <Projects
                selectedProject={selectedProject}
                setSelectedProject={setSelectedProject}
              />
            )}
            {content === 'about' && <About />}
            {content === 'contact' && <Contact />}
            {content === 'djok' && (
              <ProjectsDJOK
                selectedProject={selectedProject}
                setSelectedProject={setSelectedProject}
              />
            )}
          </main>
        </div>
        <Footer></Footer>
      </div>
  );
}

export default App;
