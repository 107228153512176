import React from "react";

function SecondNav({ handleNavigationClick, content }) {
  const headerStyle = {
    padding: "10px",
    borderBottom: "1px solid",
  };

  return (
    <div style={headerStyle}>
      <div className="flex sm:flex-row sm:pt-4 sm:pb-4 justify-center items-center">
        <div className="sm:flex gap-5 justify-between">
          <div className="flex flex-row gap-4">
            <div className="flex flex-col gap-4 sm:flex-row">
              <a
                className={`border text-center py-2 px-4 w-full sm:px-10 rounded-full hover:border-bubble-gum hover:text-bubble-gum hover:font-bold text-sm ${
                  content === "about" ? "text-bubble-gum font-bold" : ""
                }`}
                href="#about"
                onClick={() => handleNavigationClick("about")}
              >
                About
              </a>
              <a
                className={`border text-center py-2 px-4 w-full sm:px-10 rounded-full hover:border-bubble-gum hover:text-bubble-gum hover:font-bold text-sm ${
                  content === "projects" ? "text-bubble-gum font-bold" : ""
                }`}
                href="#projects"
                onClick={() => handleNavigationClick("projects")}
              >
                Projects
              </a>
            </div>
            <div className="flex flex-col gap-4 sm:flex-row">
              {/* <a
                className={`border text-center py-2 px-4 w-full sm:px-10 rounded-full hover:border-bubble-gum hover:text-bubble-gum hover:font-bold text-sm ${content === 'contact' ? 'text-bubble-gum font-bold' : ''}`}
                href="#footer"
              >
                Contact
              </a> */}

              <a
                className={`border text-center py-2 px-4 w-full sm:px-10 rounded-full hover:border-bubble-gum hover:text-bubble-gum hover:font-bold text-sm ${
                  content === "djok" ? "text-bubble-gum font-bold" : ""
                }`}
                href="#djok"
                onClick={() => handleNavigationClick("djok")}
              >
                DJ OK
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SecondNav;
