import React from 'react';
import { TwitchEmbed } from 'react-twitch-embed';

const TwitchPlayer = ({ channel, width, height }) => {
  return (
    <div className='border-8 border-bermuda rounded-lg'>


    <TwitchEmbed
      channel={channel}
      width={width} // Set custom width
      height={height} // Set custom height
      theme="light" // Adjust the theme as needed
      muted
      withChat={true} 
    />
        </div>
  );
};

export default TwitchPlayer;