import React from "react";

function About() {
  return (
    <div className="flex flex-col text-center sm:text-left sm:flex-row ">
      <div className="flex-1">
        <div className="flex flex-col mt-10 sm:mt-40">
          <img
            src={
              "https://portfolio-elisa-2023.s3.eu-west-1.amazonaws.com/ezgif.com-gif-maker.gif"
            }
            alt="Your GIF"
          />
        </div>
      </div>
      <div className="flex-1">
        <div className="flex flex-col gap-10 mt-5 sm:mt-0">
          <h1 className="font-bold text-2xl">Who am I?</h1>
          <p className="font-custom text-left">
            I am a 22 yr old programmer and UX designer. I graduated with a bachelor's degree in software engineering from the University of Iceland in June
            2023, after taking my last semester abroad in Madrid. I grew up in
            London but later moved to Iceland, where I am situated now.
          </p>
          <h1 className="font-bold text-2xl">My work</h1>
          <p className="text-left">
            During my time studying my degree, I found myself drawn to front end
            design and programming, along with computer graphics. I also gained
            a lot of interest in data visualisation when I took part in a research project. Throughout my
            degree and beyond, I have developed software for various
            companies. Over time, I became increasingly involved in digital
            design as well. In the dynamic environment of small startups,
            versatility is key, and I discovered the importance of blending
            development skills with design knowledge. This hands-on experience
            allowed me to refine my abilities in both areas.
          </p>
          <h1 className="font-bold text-2xl">Creative projects</h1>
          <p className="text-left">
            Learning to program unlocked a new world of creativity for me,
            leading me to explore various forms of digital expression. I enjoy
            delving into diverse creative endevours such as 3D modelling,
            music production, DJing and live streaming my sets. Music is one of
            my greatest passions and I am fascinated by the technical aspects that
            surround it. Whether it's crafting posters, organizing events, or
            producing music, I love every aspect of the creative process. I find
            the most fulfilment in projects that combine my interests.
          </p>
          <h1 className="font-bold text-2xl">Want to know more?</h1>
          <a
            className=" text-l text-bubble-gum font-bold mb-10 hover:text-bermuda"
            target="_blank"
            href="https://portfolio-elisa-2023.s3.eu-west-1.amazonaws.com/resumeMR.pdf"
            download
          >
            Download my resume!
          </a>
        </div>
      </div>
    </div>
  );
}

export default About;
