import React from "react";
import { useMediaQuery } from "react-responsive";
function RandomImageGrid({ imageObjects }) {
  // Repeat the image objects to fill a 4x5 grid
  const repeatedImages = Array.from(
    { length: 20 },
    (_, index) => imageObjects[index % imageObjects.length]
  );

  return (
    <div className="grid grid-cols-2 sm:grid-cols-4 gap-5 bg-gray-200 bg-opacity-70 rounded-md relative">
      {/* Background grid */}
      {repeatedImages.map((image, index) => (
        <div
          key={index}
          className="relative overflow-hidden aspect-w-1 aspect-h-1"
        >
          <img
            src={image.url}
            alt={image.caption}
            className="object-cover w-full h-full opacity-40"
          />
        </div>
      ))}
      <div className="absolute top-1/8 inset-x-0 mx-auto text-center">
        <div className="max-w-lg mx-auto">
          <p className="text-5xl mt-20 font-bold mb-5 text-bermuda">DJ OK</p>
          <div className="text-l text-left  leading-8">
            <p>
              DJ OK is a self-initiated creative project between friends. Me and my
              dear friend Íris started DJ-ing a few months ago and recorded
              mixes in our free time, and created covers for them with our
              friend Katrin who is in graphic design. We are into the idea of
              DJ-ing in weirder locations and have a regular gig at 7:00 in the
              morning in Kaffifélagið, Iceland's smallest coffee shop where the
              country's poets and philosophers come together every morning. We
              have DJed in wine bars and many events but my favorite gig was
              when we did a set in our local swimming pool! 
            </p>
            <p className="mt-10">
              Without knowing, the combination of unique locations, ironic
              online persona, and visual art led DJ ÓK to become more of a
              "performance art," which was a pleasant surprise. This project is
              dear to me because I love collaborating with my creative friend
              group, coming up with ideas together for covers, mixes, and DJ set
              venues. You can view our next DJ set live on DJ OK TV.
            </p>

            <div className="mt-20 text-xl">
              <p>Check us out on   <a
                  href="https://soundcloud.com/djok-889666396"
                  className="text-3xl font-bold text-bermuda hover:text-bubble-gum"
                >
                  Soundcloud!
                </a></p>
              <p>
                Take a look at our{" "}
                <a
                  href="https://www.instagram.com/djokokokokokok/"
                  className="text-3xl font-bold text-bermuda hover:text-bubble-gum"
                >
                  Instagram!
                </a>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default RandomImageGrid;
