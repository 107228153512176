import React, { useState, useEffect } from "react";

import ProjectDetailDJOK from "./ProjectDetailDJOK.js"; // Import your new component




function ProjectsDJOK({ selectedProject, setSelectedProject }) {

  const projects = [
    {
        title: "DJ ÓK",
        date: "Ongoing",
        imageUrl: "https://portfolio-elisa-2023.s3.eu-west-1.amazonaws.com/images/DJOKINsta.png",
      },
    {
      title: "DJ OK TV",
      date: "ongoing",
      imageUrl: "https://portfolio-elisa-2023.s3.eu-west-1.amazonaws.com/toomfoolery+(1).jpg",
    },


    // Add more projects as needed
  ];

  // Function to handle card click and set the selected project
  const handleCardClick = (index) => {
    window.scrollTo(0, 0);
    setSelectedProject(projects[index]);
  };

  // Function to handle closing the selected project
  const handleCloseProject = () => {
    setSelectedProject(null);
  };



  return (
    <div className="container mx-auto">
      {selectedProject ? (
        <ProjectDetailDJOK project={selectedProject} onClose={handleCloseProject} />
      ) : (
        <button className="grid grid-cols-1 sm:grid-cols-2 w-full gap-4">
          {projects.map((project, index) => (
            <div
              key={index}
              className="border bg-white rounded-lg shadow-md"
              onClick={() => handleCardClick(index)}
            >
              <img
                src={project.imageUrl}
                alt={`Image for ${project.title}`}
                className="w-full border-b h-96 object-cover rounded-t-lg mb-2"
              />
              <div className="p-4 text-left">
                <h2 className="text-lg font-semibold">{project.title}</h2>
                <p className="text-gray-600">Date: {project.date}</p>
              </div>
            </div>
          ))}
        </button>
      )}
      <div>
     
      </div>
    </div>
  );
}

export default ProjectsDJOK;
