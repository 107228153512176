import React, { useState, useEffect } from "react";
import axios from "axios";
import RandomImageGrid from "./RandomImageGrid";
import YouTubePlayer from "./YoutubePlayer";
import CountdownTimer from "./CountDownTimer";
import TwitchPlayer from "./TwitchPlayer"; // Path to your TwitchPlayer component

function ProjectDetailDJOK({ project, onClose }) {
  const apiKey = "AIzaSyD9O8o8nvLj-sDVd-IPAbyFil46054UtZE";
  const channelId = "UClDxJiVwB5_qnaHoxqdgrBg";
  const [liveVideoId, setLiveVideoId] = useState(null);

  // useEffect(() => {
  //   const fetchLiveVideoId = async () => {
  //     const videoId = await getLiveVideoId(apiKey, channelId);
  //     setLiveVideoId(videoId);
  //   };

  //   fetchLiveVideoId();

  //   // Fetch live video ID every minute (or your preferred interval)
  //   const intervalId = setInterval(fetchLiveVideoId, 60000);

  //   return () => clearInterval(intervalId);
  // }, [apiKey, channelId]);

  return (
    <div className="project-detail">
      {project.title === "DJ ÓK" && (
        <div>
          <RandomImageGrid
            imageObjects={[
              {
                url: "https://portfolio-elisa-2023.s3.eu-west-1.amazonaws.com/images/DJOK-live1Compress.png",
                caption: "Ég er ekki í maníu!",
              },
              {
                url: "https://portfolio-elisa-2023.s3.eu-west-1.amazonaws.com/images/djok24nov.jpg",
                caption: "Spinning jello cake",
              },
              {
                url: "https://portfolio-elisa-2023.s3.eu-west-1.amazonaws.com/images/DJOKgrandtour.jpg",
                caption: "Elisa logo",
              },
              {
                url: "https://portfolio-elisa-2023.s3.eu-west-1.amazonaws.com/images/ketaminmix-Compress.png",
                caption: "Elisa logo",
              },
              {
                url: "https://portfolio-elisa-2023.s3.eu-west-1.amazonaws.com/images/irismixtapez.jpg",
                caption: "Elisa logo",
              },
              {
                url: "https://portfolio-elisa-2023.s3.eu-west-1.amazonaws.com/images/DJOKINsta.png",
                caption: "Elisa logo",
              },
              {
                url: "https://portfolio-elisa-2023.s3.eu-west-1.amazonaws.com/images/elisaakoku.jpg",
                caption: "Elisa logo",
              },
              {
                url: "https://portfolio-elisa-2023.s3.eu-west-1.amazonaws.com/images/djokalbumcover+(1).png",
                caption: "Elisa logo",
              },
              {
                url: "https://portfolio-elisa-2023.s3.eu-west-1.amazonaws.com/images/l%C3%A6knavaktin+(1).png",
                caption: "Elisa logo",
              },
              // Repeat this for the remaining images
            ]}
          />
          <div> </div>
        </div>
      )}
     {project.title === "DJ OK TV" && (
  <div>
    <div>
      <div className="flex flex-col items-center text-center pb-10">
        <p className="text-xl sm:text-3xl font-bold mb-10"> DJ ÓK TV!</p>
        {/* <CountdownTimer></CountdownTimer> */}
      </div>
      <TwitchPlayer channel="djoktivi" width="100%" height="600px" />
      <div className="flex flex-col items-center">
        {/* <YouTubePlayer videoId={liveVideoId} /> */}
        {/* Replace YOUR_TWITCH_CHANNEL_NAME with your Twitch channel name */}
        <div className="max-w-full text-xs sm:text-sm mt-4 sm:p-8 md:mr-8 md:ml-8">
          <p>
            All broadcasted lives will be uploaded to my{" "}
            <a
              className="text-xl text-bubble-gum hover:text-bermuda"
              href="https://www.youtube.com/@elisadavidsson9827/videos"
              target="_blank"
              rel="noopener noreferrer"
            >
              YouTube
            </a>
            .
          </p>
        </div>
      </div>
      <div className="flex flex-col items-left ml-4">
        <div className="max-w-full p-8 m-4">
          <p className="text-xl pb-8 font-bold">Lives archive</p>
          <div className="flex flex-wrap gap-3 justify-between">
          <div className="w-1/3">
              <a
                href="https://www.youtube.com/watch?v=eIiaiu_8EAc&t=1806s"
                target="_blank"
                rel="noopener noreferrer"
                className="block aspect-square"
              >
                <div className="max-w-sm hover:opacity-60 text-bermuda">
                  <img
                    src="https://portfolio-elisa-2023.s3.eu-west-1.amazonaws.com/toomfoolery+(1).jpg"
                    alt="LiveSet"
                    className="w-full h-full object-cover"
                  />
                  <p className="text-bubble-gum hover:text-bermuda">
                    DJ ÖRSÍ - JERSEY CLUB
                  </p>
                </div>
              </a>
            </div>
            <div className="w-1/3">
              <a
                href="https://www.youtube.com/watch?v=EVXhIASN9iE&t=2663s"
                target="_blank"
                rel="noopener noreferrer"
                className="block aspect-square"
              >
                <div className="max-w-sm hover:opacity-60 text-bermuda">
                  <img
                    src="https://portfolio-elisa-2023.s3.eu-west-1.amazonaws.com/toomfoolery+(1).jpg"
                    alt="LiveSet"
                    className="w-full h-full object-cover"
                  />
                  <p className="text-bubble-gum hover:text-bermuda">
                    DRIF SPECIAL VOL 2
                  </p>
                </div>
              </a>
            </div>
            <div className="w-1/3">
              <a
                href="https://www.youtube.com/live/37EyNw9j49o?si=W260nFZ51IaaOwiH"
                target="_blank"
                rel="noopener noreferrer"
                className="block aspect-square"
              >
                <div className="max-w-sm hover:opacity-60 text-bermuda">
                  <img
                    src="https://portfolio-elisa-2023.s3.eu-west-1.amazonaws.com/toomfoolery+(1).jpg"
                    alt="LiveSet"
                    className="w-full h-full object-cover"
                  />
                  <p className="text-bubble-gum hover:text-bermuda">
                    JON EDVARD SKRILLEX SPECIAL
                  </p>
                </div>
              </a>
            </div>
            <div className="w-1/3">
              <a
                href="https://www.youtube.com/watch?v=dIoA7rcR2Io&t=1885s"
                target="_blank"
                rel="noopener noreferrer"
                className="block aspect-square"
              >
                <div className="max-w-sm hover:opacity-60 text-bermuda">
                  <img
                    src="https://portfolio-elisa-2023.s3.eu-west-1.amazonaws.com/images/drifspecial.jpg"
                    alt="LiveSet"
                    className="w-full h-full object-cover"
                  />
                  <p className="text-bubble-gum hover:text-bermuda">
                    DJ ÓK DRIF SPECIAL
                  </p>
                </div>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
)}

    </div>
  );
}

export default ProjectDetailDJOK;
