import React from 'react';

function Contact() {
  return (
    <div>
    <p>Contact</p>
    </div>

  );
}

export default Contact;