import React, { useState, useEffect } from "react";
import ProjectDetail from "./ProjectDetail"; // Import your new component





function Projects({ selectedProject, setSelectedProject }) {

  const projects = [

    {
      title: "Pikkolo optimization",
      date: "Summer 2022",
      imageUrl: "https://portfolio-elisa-2023.s3.eu-west-1.amazonaws.com/images/plotone.png",
    },
    {
      title: "Blender museum",
      date: "ongoing",
      imageUrl: "https://portfolio-elisa-2023.s3.eu-west-1.amazonaws.com/images/just+a+girl.png",
    },
  
    {
      title: "Indo",
      date: "Summer 2022",
      imageUrl: "https://portfolio-elisa-2023.s3.eu-west-1.amazonaws.com/images/indo.png",
    },


 
    
  
    {
      title: "InkUp",
      date: "Ongoing",
      imageUrl: "https://portfolio-elisa-2023.s3.eu-west-1.amazonaws.com/images/inkedup.card.png",
    },
  
    // Add more projects as needed
  ];

  // Function to handle card click and set the selected project
  const handleCardClick = (index) => {
    window.scrollTo(0, 0);
    setSelectedProject(projects[index]);
  };

  // Function to handle closing the selected project
  const handleCloseProject = () => {
    setSelectedProject(null);
  };



  return (
    <div className="container mx-auto">
      {selectedProject ? (
        <ProjectDetail project={selectedProject} onClose={handleCloseProject} />
      ) : (
        <button className="grid grid-cols-1 sm:grid-cols-2 w-full gap-4">
          {projects.map((project, index) => (
            <div
              key={index}
              className="border bg-white rounded-lg shadow-md"
              onClick={() => handleCardClick(index)}
            >
              <img
                src={project.imageUrl}
                alt={`Image for ${project.title}`}
                className="w-full border-b h-96 object-cover rounded-t-lg mb-2"
              />
              <div className="p-4 text-left">
                <h2 className="text-lg font-semibold">{project.title}</h2>
                <p className="text-gray-600">Date: {project.date}</p>
              </div>
            </div>
          ))}
        </button>
      )}
      <div>
     
      </div>
    </div>
  );
}

export default Projects;
